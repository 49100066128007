<template>
<div class="dealInfo">
	<el-form-item prop="originOrder">
		<span>退费计算<span class="start">*</span></span>
		<el-row>
			<div v-for="(dropOutTable, tableIndex) in dropOutTableList" :key="tableIndex"  class="dropout_table" :class="{single: dropOutTableList.length === 1}">
				<div class="goodsAndClass" style="background: #f5f7fa;overflow: hidden;padding: 10px 20px;margin-bottom: 18px">
					<el-col :span="6">
						<p style="color: #999">商品名称</p>
						<p v-if="classInfo[tableIndex]">{{classInfo[tableIndex].goodsName}}</p>
						<p v-else>- -</p>
					</el-col>
					<el-col :span="4">
						<p style="color: #999">已收金额</p>
						<p v-if="classInfo[tableIndex]">￥{{classInfo[tableIndex].receivedMoney | decimal2}}</p>
						<p v-else>0.0 -</p>
					</el-col>
					<!-- 成考、网校不显示已上课时金额 -->
					<el-col :span="9" v-if="!(orderExtraInfo[0].goodsTemplate === goodsTemplateEnum.ckdp || orderExtraInfo[0].goodsTemplate === goodsTemplateEnum.wjdp)">
						<p style="color: #999">已上课时金额</p>
						<p class="classHis"> 
							<span>线上课程 </span>
							<span v-if="classInfo[tableIndex] && classInfo[tableIndex].onlineViewType !=3">
									<span v-show="classInfo[tableIndex].onlineViewType ==0 || classInfo[tableIndex].onlineViewType == 2">
										{{classInfo[tableIndex].ineffectiveMonth}}/{{classInfo[tableIndex].effectiveMonth}} 月，
									已上课时费用{{classInfo[tableIndex].onlineMonthSpendMoney}}元
									</span>
									<span v-show="classInfo[tableIndex].onlineViewType ==1 || classInfo[tableIndex].onlineViewType == 2">
										{{classInfo[tableIndex].signCount}}/{{classInfo[tableIndex].lessonCount}} 课时，
									已上课时费用{{classInfo[tableIndex].onlineSpendMoney}}元
									</span>
								</span>
							<span v-else>- -</span>
						</p>
						<p class="classHis">
							<span>线下课程 </span>
							<span v-if="classInfo[tableIndex]  && classInfo[tableIndex].underlineViewType !=3">
								<span v-show="classInfo[tableIndex].underlineViewType ==0 || classInfo[tableIndex].underlineViewType == 2"> {{classInfo[tableIndex].ineffectiveMonth}}/{{classInfo[tableIndex].effectiveMonth}} 月，
								已上课时费用{{classInfo[tableIndex].underlineMonthSpendMoney}}元</span>
								<span v-show="classInfo[tableIndex].underlineViewType ==1 || classInfo[tableIndex].underlineViewType == 2">{{classInfo[tableIndex].signCount}}/{{classInfo[tableIndex].lessonCount}} 课时，
								已上课时费用{{classInfo[tableIndex].underlineSpendMoney}}元</span>
								</span>
							<span v-else>- -</span>
						</p>
					</el-col>
					<el-col :span="5" v-if="orderExtraInfo[0] && orderExtraInfo[0].goodsTemplate === goodsTemplateEnum.cjzh">
						<p style="color: #999">核定可退费金额</p>
						<p style="color:#F54955;">{{ classInfo[tableIndex].refundMoney | decimal2 }}元</p>
					</el-col>
					<span class="expand" v-if="dropOutTableList.length > 1" @click="toggleDropoutTable(tableIndex)">{{!classInfo[tableIndex].expand ? '详情' : '收起' }}<i :class="[!classInfo[tableIndex].expand ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i></span>
				</div>
					<div v-show="classInfo[tableIndex].expand">
						<div class="inout_bar"><b>收支项目</b><i class="el-icon-circle-plus color" @click="addDropOut(tableIndex)"> 添加扣费项目</i></div>
						<!-- 退费计算表格 -->
						<el-table
								border
								stripe
								size="mini"
								element-loading-text="拼命加载中"
								:row-style="{ height: '40px' }"
								:cell-style="{ padding: '0px' }"
								style="width: 100%;"
								header-cell-class-name="normal-table-header"
								show-summary
								class="tuifeiTable"
								:summary-method="onCustomSummaryDropoutBox"
								:data="dropOutTable"
						>
							<template v-for="item of dropOutColumns">
								<!-- 应收金额 -->
								<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'receivableMoney'" :label="item.label" :key="item.prop" :prop="item.prop">
									<template v-slot:default="{ row }">
										{{row.canDelete ? '--' : row.receivableMoney }}
									</template>
								</el-table-column>
								<!-- 已收金额 -->
								<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivedMoney'" :label="item.label" :key="item.prop" :prop="item.prop">
									<template v-slot:default="{ row }">
										{{row.canDelete ? '--' : row.receivedMoney }}
									</template>
								</el-table-column>
								<!-- 扣费金额 -->
								<el-table-column :show-overflow-tooltip="true" class-name="deduction-column" v-else-if="item.prop === 'deductionMoney'" :key="item.prop" :label="item.label" :prop="item.prop">
                  <template slot="header">
                    <div>{{ item.label }}
                      <el-tooltip class="item" effect="light" content="售后订单扣减的金额，扣减的金额不用于退费" placement="top-start">
                        <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
                      </el-tooltip>
                    </div>
                  </template>
									<div slot-scope="scope" data-type="1">
										<!-- <el-input class="deduction-input" v-model="scope.row.deductionMoney" type="number"  @blur="onDeductionMoneyBlur(dropOutTable, scope.row, scope.row.$index)" @input="onDeductionMoneyInput(scope.row, scope.row.$index)"/> -->
                    <el-input class="deduction-input" v-model="scope.row.deductionMoney" type="number" size="mini" @blur="onDeductionMoneyBlur(dropOutTable, scope.row, scope.row.$index)" @input="onDeductionMoneyInput(scope.row, scope.row.$index)" />
										<!-- <span>{{scope.row.deductionMoney}}</span> -->
									</div>
								
								</el-table-column>

								<!-- 核定可退款金额 -->
								<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundableMoney'" :label="item.label" :key="item.prop" :prop="item.prop">
                  <template slot="header">
                    <div>{{ item.label }}
                      <el-tooltip class="item" effect="light" content="售后订单扣减后剩余的金额，可用于贷款平台退费和学员退费" placement="top-start">
                          <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
                      </el-tooltip>
                    </div>
                  </template>
									<template v-slot:default="{ row }">
										<span>{{ row.refundableMoney }}</span>
									</template>
								</el-table-column>

								<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'canDo'" :label="item.label" :key="item.prop" :prop="item.prop" width="150px">
									<template v-slot:default="{ row }">
										<el-link type="primary" @click="onDeleteDropOutTableRow(dropOutTable, tableIndex, row)" v-if="row.canDelete">删除</el-link>
										<p v-else>--</p>
									</template>
								</el-table-column>

								<el-table-column :show-overflow-tooltip="true" v-else :key="item.label" :label="item.label" :width="item.width" :prop="item.prop" />
							</template>
						</el-table>

					</div>
			</div>
		</el-row>
	</el-form-item>
</div>	
</template>

<script>
import { goodsTemplateEnum } from '@/views/order/constants';
import { accAdd, accSub } from '@/utils/mathMethod';
export default {
	props: {
		dropOutTableList: Array,
		classInfo: Array,
		orderExtraInfo: [Array, Object]
	},
	data(){
		return {
			dropOutColumns: [
        { label: "收支项目", prop: "orderInoutProjectName",width:"160px"},
        { label: "应收金额", prop: "receivableMoney",width:"100px" },
        { label: "已收金额", prop: "receivedMoney",width:"100px" },
        { label: "扣费金额", prop: "deductionMoney", width:"130px" },
        { label: "核定可退款金额", prop: "refundableMoney",width:"130px" },
        { label: "操作", prop: "canDo", width:"140px"},
      ],
			goodsTemplateEnum,
		}
	},
	methods: {
		onDeleteDropOutTableRow(dropOutTable, tableIndex, row){
      const deleteIndex = this.dropOutTableList[tableIndex].findIndex(item => item.inoutProjectId == row.inoutProjectId)
      console.log('[onDeleteDropOutTableRow]', row, deleteIndex);
      this.dropOutTableList[tableIndex].splice(deleteIndex, 1)
    },
		//自定义退费表格合计
    onCustomSummaryDropoutBox(param){
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        sums[index] = values.reduce((prev, curr) => {
          const value = isNaN(+curr) ? 0 : +curr
          const preValue = isNaN(+prev) ? 0 : +prev
          return preValue + value
        }, 0);
        if(column.property === 'canDo'){
          //操作栏不做统计
          sums[index] = ''
        }
        else if(!isNaN(+sums[index])){
          const total = +accAdd(0, +sums[index], 2)
          // sums[index] = total < 0  ? 0 : total
          sums[index] = total
        }
        
      });
      return sums;
    },
		toggleDropoutTable(index){
      this.$set(this.classInfo[index], 'expand', !this.classInfo[index].expand)
    },
		addDropOut(tableIndex){
			this.$emit('addDropOut', tableIndex)
		},
		onDeductionMoneyBlur(dropOutTable, row){
      console.log("本行",row); 
      row.edit = false
      let deductionMoney = +row.deductionMoney
      if(deductionMoney === 0){
        row.deductionMoney = 0
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2)
        return 
      }
      if(isNaN(deductionMoney)){
        row.deductionMoney = 0
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2)
        return this.$message.error('请输入合法的金额')
      }
      // 小于0过滤 
      if (row.deductionMoney < 0) {
        row.deductionMoney = 0
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2)
        return this.$message.error("不能小于0")
      }
      row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2)
    },
		onDeductionMoneyInput(row){
      let val = ( row.deductionMoney + '').split('.')
      if(val.length > 1 && (val[1] + '').length >= 3){
        //命中小数点超出2位
        row.deductionMoney = val[0] + '' + '.' + (val[1] + '').substr(0, 2)
        return row.deductionMoneye
      } 
    },
	}
}
</script>

<style lang="scss" scoped>
.dealInfo{
	.dealInfoBox{
		margin-bottom: 0;
	}
	.goodsAndClass{
		position: relative;
		.goodsName{
			margin-right: 26px;
			display: flex;
			span:nth-child(1){margin-right: 10px;}
			span:nth-child(2){font-weight: bold;max-width: 200px;overflow: hidden;display: inline-block;text-overflow: ellipsis;white-space: nowrap;}
			.goodsSpecName{
				font-size: 10px;
				color: #999;
			}
		}
		.reciveMoney{
			margin-right: 26px;
			span:nth-child(1){margin-right: 10px;}
			span:nth-child(2){font-weight: bold;}
		}
		.classHis{
			margin-right: 26px;
			&>span{margin-right: 10px;}
		}
		.studyed{
			margin-right: 26px;
			span:nth-child(1){margin-right: 10px;}
			span:nth-child(2){font-weight: bold;}
		}
		.canReturn{
			margin-right: 26px;
			span:nth-child(1){margin-right: 10px;}
			span:nth-child(2){font-weight: bold;}
		}
		.expand{
			position: absolute;
			right: 24px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			color: #337AFF;
		}
		.el-col {
			p {
				margin-bottom: 0;
				line-height: 26px;
			}
		}
		.line1{
			display: flex;
		}
		.line2{
			display: flex;
			padding-left: 1.6rem;
			.right{
				margin-left: 30px;
			}
		}
		.line3{
			.right{
				display: flex;
			}
		}
	}
}
::v-deep .tuifeiTable {
	font-size: 14px;
	margin-bottom: 15px;
	thead tr th{
		background-color: #fff;
	}
	>.el-table__body-wrapper tr:hover > td .el-input{
		// color: red;
		display: block;
	}
	.el-input {
		// display: none;
	}
	.current-row .el-input {
		display: block;
	}
	.current-row .el-input + span {
		display: none;
	}
	.el-input__inner{
		padding: 0 10px;
	}
	// .hover-row .el-input,
	// tr:hover>td .el-input {
	// 	font-size: 14px;
	// 	display: block;
	// 	padding: 0 10px;
	// 	.el-input__inner {
	// 		height: 35px;
	// 		border-color: #337AFF;
	// 		padding-left: 10px;
	// 		width: 100%;
	// 	}
	// }
	.hover-row .el-input + span,
	tr:hover >td .el-input + span{
		// display: none;
	}
}
</style>