var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dealInfo" },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "originOrder" } },
        [
          _c("span", [
            _vm._v("退费计算"),
            _c("span", { staticClass: "start" }, [_vm._v("*")])
          ]),
          _c(
            "el-row",
            _vm._l(_vm.dropOutTableList, function(dropOutTable, tableIndex) {
              return _c(
                "div",
                {
                  key: tableIndex,
                  staticClass: "dropout_table",
                  class: { single: _vm.dropOutTableList.length === 1 }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "goodsAndClass",
                      staticStyle: {
                        background: "#f5f7fa",
                        overflow: "hidden",
                        padding: "10px 20px",
                        "margin-bottom": "18px"
                      }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("p", { staticStyle: { color: "#999" } }, [
                          _vm._v("商品名称")
                        ]),
                        _vm.classInfo[tableIndex]
                          ? _c("p", [
                              _vm._v(
                                _vm._s(_vm.classInfo[tableIndex].goodsName)
                              )
                            ])
                          : _c("p", [_vm._v("- -")])
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("p", { staticStyle: { color: "#999" } }, [
                          _vm._v("已收金额")
                        ]),
                        _vm.classInfo[tableIndex]
                          ? _c("p", [
                              _vm._v(
                                "￥" +
                                  _vm._s(
                                    _vm._f("decimal2")(
                                      _vm.classInfo[tableIndex].receivedMoney
                                    )
                                  )
                              )
                            ])
                          : _c("p", [_vm._v("0.0 -")])
                      ]),
                      !(
                        _vm.orderExtraInfo[0].goodsTemplate ===
                          _vm.goodsTemplateEnum.ckdp ||
                        _vm.orderExtraInfo[0].goodsTemplate ===
                          _vm.goodsTemplateEnum.wjdp
                      )
                        ? _c("el-col", { attrs: { span: 9 } }, [
                            _c("p", { staticStyle: { color: "#999" } }, [
                              _vm._v("已上课时金额")
                            ]),
                            _c("p", { staticClass: "classHis" }, [
                              _c("span", [_vm._v("线上课程 ")]),
                              _vm.classInfo[tableIndex] &&
                              _vm.classInfo[tableIndex].onlineViewType != 3
                                ? _c("span", [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.classInfo[tableIndex]
                                                .onlineViewType == 0 ||
                                              _vm.classInfo[tableIndex]
                                                .onlineViewType == 2,
                                            expression:
                                              "classInfo[tableIndex].onlineViewType ==0 || classInfo[tableIndex].onlineViewType == 2"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .ineffectiveMonth
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .effectiveMonth
                                            ) +
                                            " 月， 已上课时费用" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .onlineMonthSpendMoney
                                            ) +
                                            "元 "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.classInfo[tableIndex]
                                                .onlineViewType == 1 ||
                                              _vm.classInfo[tableIndex]
                                                .onlineViewType == 2,
                                            expression:
                                              "classInfo[tableIndex].onlineViewType ==1 || classInfo[tableIndex].onlineViewType == 2"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .signCount
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .lessonCount
                                            ) +
                                            " 课时， 已上课时费用" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .onlineSpendMoney
                                            ) +
                                            "元 "
                                        )
                                      ]
                                    )
                                  ])
                                : _c("span", [_vm._v("- -")])
                            ]),
                            _c("p", { staticClass: "classHis" }, [
                              _c("span", [_vm._v("线下课程 ")]),
                              _vm.classInfo[tableIndex] &&
                              _vm.classInfo[tableIndex].underlineViewType != 3
                                ? _c("span", [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.classInfo[tableIndex]
                                                .underlineViewType == 0 ||
                                              _vm.classInfo[tableIndex]
                                                .underlineViewType == 2,
                                            expression:
                                              "classInfo[tableIndex].underlineViewType ==0 || classInfo[tableIndex].underlineViewType == 2"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .ineffectiveMonth
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .effectiveMonth
                                            ) +
                                            " 月， 已上课时费用" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .underlineMonthSpendMoney
                                            ) +
                                            "元"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.classInfo[tableIndex]
                                                .underlineViewType == 1 ||
                                              _vm.classInfo[tableIndex]
                                                .underlineViewType == 2,
                                            expression:
                                              "classInfo[tableIndex].underlineViewType ==1 || classInfo[tableIndex].underlineViewType == 2"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.classInfo[tableIndex].signCount
                                          ) +
                                            "/" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .lessonCount
                                            ) +
                                            " 课时， 已上课时费用" +
                                            _vm._s(
                                              _vm.classInfo[tableIndex]
                                                .underlineSpendMoney
                                            ) +
                                            "元"
                                        )
                                      ]
                                    )
                                  ])
                                : _c("span", [_vm._v("- -")])
                            ])
                          ])
                        : _vm._e(),
                      _vm.orderExtraInfo[0] &&
                      _vm.orderExtraInfo[0].goodsTemplate ===
                        _vm.goodsTemplateEnum.cjzh
                        ? _c("el-col", { attrs: { span: 5 } }, [
                            _c("p", { staticStyle: { color: "#999" } }, [
                              _vm._v("核定可退费金额")
                            ]),
                            _c("p", { staticStyle: { color: "#F54955" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("decimal2")(
                                    _vm.classInfo[tableIndex].refundMoney
                                  )
                                ) + "元"
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.dropOutTableList.length > 1
                        ? _c(
                            "span",
                            {
                              staticClass: "expand",
                              on: {
                                click: function($event) {
                                  return _vm.toggleDropoutTable(tableIndex)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.classInfo[tableIndex].expand
                                    ? "详情"
                                    : "收起"
                                )
                              ),
                              _c("i", {
                                class: [
                                  !_vm.classInfo[tableIndex].expand
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up"
                                ]
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.classInfo[tableIndex].expand,
                          expression: "classInfo[tableIndex].expand"
                        }
                      ]
                    },
                    [
                      _c("div", { staticClass: "inout_bar" }, [
                        _c("b", [_vm._v("收支项目")]),
                        _c(
                          "i",
                          {
                            staticClass: "el-icon-circle-plus color",
                            on: {
                              click: function($event) {
                                return _vm.addDropOut(tableIndex)
                              }
                            }
                          },
                          [_vm._v(" 添加扣费项目")]
                        )
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "tuifeiTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            stripe: "",
                            size: "mini",
                            "element-loading-text": "拼命加载中",
                            "row-style": { height: "40px" },
                            "cell-style": { padding: "0px" },
                            "header-cell-class-name": "normal-table-header",
                            "show-summary": "",
                            "summary-method": _vm.onCustomSummaryDropoutBox,
                            data: dropOutTable
                          }
                        },
                        [
                          _vm._l(_vm.dropOutColumns, function(item) {
                            return [
                              item.prop === "receivableMoney"
                                ? _c("el-table-column", {
                                    key: item.prop,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      prop: item.prop
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.canDelete
                                                      ? "--"
                                                      : row.receivableMoney
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : item.prop === "receivedMoney"
                                ? _c("el-table-column", {
                                    key: item.prop,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      prop: item.prop
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.canDelete
                                                      ? "--"
                                                      : row.receivedMoney
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : item.prop === "deductionMoney"
                                ? _c(
                                    "el-table-column",
                                    {
                                      key: item.prop,
                                      attrs: {
                                        "show-overflow-tooltip": true,
                                        "class-name": "deduction-column",
                                        label: item.label,
                                        prop: item.prop
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return _c(
                                                "div",
                                                { attrs: { "data-type": "1" } },
                                                [
                                                  _c("el-input", {
                                                    staticClass:
                                                      "deduction-input",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini"
                                                    },
                                                    on: {
                                                      blur: function($event) {
                                                        return _vm.onDeductionMoneyBlur(
                                                          dropOutTable,
                                                          scope.row,
                                                          scope.row.$index
                                                        )
                                                      },
                                                      input: function($event) {
                                                        return _vm.onDeductionMoneyInput(
                                                          scope.row,
                                                          scope.row.$index
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .deductionMoney,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "deductionMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.deductionMoney"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(_vm._s(item.label) + " "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "light",
                                                  content:
                                                    "售后订单扣减的金额，扣减的金额不用于退费",
                                                  placement: "top-start"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  staticStyle: {
                                                    color: "#9BACBF",
                                                    "font-size": "18px",
                                                    "margin-left": "8px"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                : item.prop === "refundableMoney"
                                ? _c(
                                    "el-table-column",
                                    {
                                      key: item.prop,
                                      attrs: {
                                        "show-overflow-tooltip": true,
                                        label: item.label,
                                        prop: item.prop
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(row.refundableMoney)
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(_vm._s(item.label) + " "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "light",
                                                  content:
                                                    "售后订单扣减后剩余的金额，可用于贷款平台退费和学员退费",
                                                  placement: "top-start"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  staticStyle: {
                                                    color: "#9BACBF",
                                                    "font-size": "18px",
                                                    "margin-left": "8px"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                : item.prop === "canDo"
                                ? _c("el-table-column", {
                                    key: item.prop,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      prop: item.prop,
                                      width: "150px"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              row.canDelete
                                                ? _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onDeleteDropOutTableRow(
                                                            dropOutTable,
                                                            tableIndex,
                                                            row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("删除")]
                                                  )
                                                : _c("p", [_vm._v("--")])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _c("el-table-column", {
                                    key: item.label,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      width: item.width,
                                      prop: item.prop
                                    }
                                  })
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }